import axios from "axios";
import React, { useEffect } from "react";
import Posts from "../components/Posts/Posts";

const HomePage: React.FC = () => {
  useEffect(() => {
    const getSezioni = async () => {
     /*  const loginData = {
        email: "direttore1@example.com",
        password: "Password123!",
      }; */
    
      try {
        const response = await axios.get('http://localhost:3000/api/public-pages');
        console.log('SEZIONI:', response.data);
      } catch (error: any) {  // Type assertion to any
        console.error('Error logging in:', error.response ? error.response.data : error.message);
      }
    };
    
    // Call the login function
    getSezioni();
  }, []);
  return (
    <div>
      <h1>Home Page</h1>
            <Posts />

    </div>
  );
};

export default HomePage;
