import React, { useState } from 'react';
import PostCard from './PostCard';

interface Post {
  id: number;
  category: string;
  title: string;
  author: string;
  date: string;
  views: number;
  excerpt: string;
  image: string;
}

const Posts: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([
    {
      id: 1,
      category: 'Travel',
      title: 'Discover Your Inner Genius To Better',
      author: 'John Doe',
      date: 'March 23, 2024',
      views: 62,
      excerpt: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    },
    {
      id: 2,
      category: 'Thinking',
      title: 'Read This To Change How You Think',
      author: 'Jane Smith',
      date: 'March 22, 2024',
      views: 51,
      excerpt: 'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    },
    {
      id: 3,
      category: 'Success',
      title: 'One Simple Word To Success',
      author: 'Michael Brown',
      date: 'March 21, 2024',
      views: 45,
      excerpt: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    },
    {
      id: 8,
      category: 'Success',
      title: 'One Simple Word To Success',
      author: 'Michael Brown',
      date: 'March 21, 2024',
      views: 45,
      excerpt: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    }
    ,
    {
      id: 4,
      category: 'Success',
      title: 'One Simple Word To Success',
      author: 'Michael Brown',
      date: 'March 21, 2024',
      views: 45,
      excerpt: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    }
    ,
    {
      id: 5,
      category: 'Success',
      title: 'One Simple Word To Success',
      author: 'Michael Brown',
      date: 'March 21, 2024',
      views: 45,
      excerpt: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    }
    ,
    {
      id: 6,
      category: 'Success',
      title: 'One Simple Word To Success',
      author: 'Michael Brown',
      date: 'March 21, 2024',
      views: 45,
      excerpt: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    }
    ,
    {
      id: 7,
      category: 'Success',
      title: 'One Simple Word To Success',
      author: 'Michael Brown',
      date: 'March 21, 2024',
      views: 45,
      excerpt: 'Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam...',
      image: 'https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg'
    }
    // Aggiungi più post demo qui se necessario
  ]);

  /*
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axiosInstance.get('/posts'); // Modifica con il percorso API corretto
        setPosts(response.data);
      } catch (error) {
        console.error('Errore nel recupero dei post:', error);
      }
    };

    fetchPosts();
  }, []);
  */

  return (
    <div className="posts">
      {posts.map(post => (
        <PostCard key={post.id} post={post} />
      ))}
    </div>
  );
};

export default Posts;
