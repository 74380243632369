import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchNavLinks } from "../../utils/fakeApi";

interface SubPage {
  id: number;
  label: string;
  url: string;
}

interface NavLink {
  id: number;
  label: string;
  url: string;
  subPages?: SubPage[];
}

const Sidebar: React.FC = () => {
  const [navLinks, setNavLinks] = useState<NavLink[]>([]);
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const [activePage, setActivePage] = useState<number | null>(null);
  const [sidebarActive, setSidebarActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getNavLinks = async () => {
      const links = await fetchNavLinks();
      setNavLinks(links as NavLink[]);
    };

    getNavLinks();
  }, []);

  useEffect(() => {
    /* const login = async () => {
      const loginData = {
        email: "direttore1@example.com",
        password: "Password123!",
      };
    
      try {
        const response = await axios.post('http://localhost:3000/api/auth/login', loginData);
        console.log('Login successful:', response.data);
      } catch (error: any) {  
        console.error('Error logging in:', error.response ? error.response.data : error.message);
      }
    };
    
    // Call the login function
    login(); */
  }, []);
  

  const handleSectionClick = (id: number, subPages?: SubPage[]) => {
    console.log()
    if (subPages && subPages.length === 1) {
      setActiveSection(id);
      setActivePage(subPages[0].id);
      handlePageClick(id, subPages[0].id, subPages[0].url);
    } else {
      setActiveSection((prevActive) => (prevActive === id ? null : id));
      setActivePage(null);
    }
  };

  const handlePageClick = (sectionId: number, pageId: number, url: string) => {
    setActivePage(pageId);
    console.log(sectionId)
    console.log(pageId)
    
   /*  navigate(url); */
    setSidebarActive(false); // Chiude il menu mobile
  };

  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  return (
    <>
      <div className="hamburger-container">
        <div className={`hamburger-menu ${sidebarActive ? "active" : ""}`} onClick={toggleSidebar}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="brand-name">Vincenzo Primerano</div>
      </div>
      <aside className={`sidebar ${sidebarActive ? "active" : ""}`}>
        <span className="close-btn" onClick={toggleSidebar}>&times;</span>
        <div className="brand">
          <h2>Vincenzo Primerano</h2>
          <div className="profile-pic"></div>
          <p>Il calcio sulle lente d'ingrandimento</p>
        </div>
        <div className="search-bar">
          <input type="text" placeholder="Enter your keyword?" />
          <button>Search</button>
        </div>
        <nav>
          <ul>
            {navLinks.map((link) => (
              <li key={link.id}>
                <a
                  href="#"
                  className={`section-link ${
                    activeSection === link.id &&
                    (!link.subPages || link.subPages.length === 1)
                      ? "active-section"
                      : ""
                  }`}
                  onClick={() => handleSectionClick(link.id, link.subPages)}
                >
                  {link.label}
                </a>
                {activeSection === link.id &&
                  link.subPages &&
                  link.subPages.length > 1 && (
                    <ul>
                      {link.subPages.map((subPage) => (
                        <li key={subPage.id}>
                          <a
                            href="#"
                            className={`page-link ${
                              activePage === subPage.id ? "active-page" : ""
                            }`}
                            onClick={() =>
                              handlePageClick(link.id, subPage.id, subPage.url)
                            }
                          >
                            {subPage.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="footer">
          <p>&copy; 2024. Design by Francesco Catania.</p>
          <div className="social-links">
            <a href="#">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="#">
              <i className="bi bi-youtube"></i>
            </a>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
