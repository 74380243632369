import React from 'react';
import Posts from '../Posts/Posts';
import RightSidebar from '../RightSidebar/RightSidebar';

interface MainContentProps {
  children?: React.ReactNode; // Optional children prop
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  return (
    <div className="main-content">
      <div className="posts-wrapper">
      {children}

      </div>
      <div className="right-wrapper">
        <RightSidebar />
      </div>
    </div>
  );
};

export default MainContent;
