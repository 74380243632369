// src/utils/fakeApi.ts
import { fakeNavLinks } from './akeNavLinks';

export const fetchNavLinks = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fakeNavLinks);
    }, 1000); 
  });
};
