import React from "react";
import PostCardOrizzontal from "../Posts/PostCardOrizzontal";

const posts = [
  {
    id: 1,
    title: "Discover Your Inner Genius To Better",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
  {
    id: 2,
    title: "Read This To Change How You Think",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
  {
    id: 3,
    title: "Read This To Change How You Think",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
  {
    id: 4,
    title: "Read This To Change How You Think",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
  // Aggiungi più post se necessario
];
const posts2 = [
  {
    id: 13,
    title: "Discover Your Inner Genius To Better",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },

  {
    id: 22,
    title: "Read This To Change How You Think",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
  {
    id: 3,
    title: "Read This To Change How You Think",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
  
  {
    id: 4,
    title: "Read This To Change How You Think",
    category: "Success",

    image:
      "https://www.nationalgeographic.it/upload/ngi-hero/cover-1682446481256-tigrotto.jpeg",
    excerpt:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit...",
    date: "March 21, 2024",
    views: 45,
    author: "Michael Brown",
  },
];
const RightSidebar: React.FC = () => {
  return (
    <aside className="right-sidebar">
      <div >
        <h3>Articoli Piu Letti</h3>
        {posts.map((post) => (
          <PostCardOrizzontal post={post} key={post.id} />
        ))}
      </div>
      <div >
        <h3>Ultimi Articoli</h3>
        {posts2.map((post) => (
          <PostCardOrizzontal post={post} key={post.id} />
        ))}
      </div>
    </aside>
  );
};

export default RightSidebar;
