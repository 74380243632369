import React from 'react';

interface PostCardProps {
    post: {
      id: number;
      category: string;
      
      title: string;
      author: string;
      date: string;
      views: number;
      excerpt: string;
      image: string;
    };
  }

const PostCardOrizzontal: React.FC<PostCardProps> = ({ post }) => {
  return (
    <div className="post-card-orizzontal">
      <img src={post.image} alt={post.title}  />
      <div className="post-card-content">
        <h5 className="post-card-title">{post.title}</h5>
        <p className="post-card-excerpt">{post.excerpt}</p>
        <p className="card-text">
          By: {post.author} | {post.date} | {post.views} views
        </p>
      </div>
    </div>
  );
};

export default PostCardOrizzontal;
