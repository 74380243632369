import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="header">
      <h1>Pagina</h1>
      <div className="search-bar">
        <input type="text" placeholder="Enter your keyword?" />
        <button>Search</button>
      </div>
    </header>
  );
};

export default Header;
