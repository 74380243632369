// src/utils/fakeNavLinks.ts
export const fakeNavLinks = [
    {
      id: 1,
      label: 'Homepages',
      url: '/homepages',
      subPages: [
        { id: 11, label: 'Home 1', url: '/homepages/home1' },
        { id: 12, label: 'Home 2', url: '/homepages/home2' },
        { id: 13, label: 'Home 1', url: '/homepages/home1' },
        { id: 14, label: 'Home 2', url: '/homepages/home2' },
        { id: 15, label: 'Home 1', url: '/homepages/home1' },
        { id: 16, label: 'Home 2', url: '/homepages/home2' },
        { id: 17, label: 'Home 1', url: '/homepages/home1' },
        { id: 18, label: 'Home 2', url: '/homepages/home2' },
      ],
    },
    {
      id: 2,
      label: 'Blog',
      url: '/blog',
      subPages: [
        { id: 21, label: 'Tech', url: '/blog/tech' },
        
      ],
    },
    {
      id: 3,
      label: 'Posts',
      url: '/posts',
      subPages: [
        { id: 31, label: 'Post 1', url: '/posts/post1' },
        { id: 32, label: 'Post 2', url: '/posts/post2' },
      ],
    },
    {
      id: 4,
      label: 'Pages',
      url: '/pages',
      subPages: [
        { id: 41, label: 'About Us', url: '/pages/about' },
        { id: 42, label: 'Services', url: '/pages/services' },
      ],
    },
    {
      id: 5,
      label: 'Contact Us',
      url: '/contact',
      subPages: [], // Nessuna sottopagina per questa voce
    },
  ];
  