import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface PostCardProps {
  post: {
    id: number;
    category: string;
    title: string;
    author: string;
    date: string;
    views: number;
    excerpt: string;
    image: string;
  };
}

const PostCard: React.FC<PostCardProps> = ({ post }) => {
  return (
    <div className="post-card" >
      <img src={post.image} alt={post.title} />
      <div className="card-body d-flex flex-column">
        <h5 className="card-title">{post.title}</h5>
       
        <p className="card-text">{post.excerpt}</p>
        <p className="card-text">
          By: {post.author} | {post.date} | {post.views} views
        </p>
      </div>
    </div>
  );
};

export default PostCard;
