import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import MainContent from "../MainContent/MainContent"; // Make sure the import matches the correct component name
import "./Layout.css";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <Sidebar />
      <div className="main-section">
        <Header />
        <MainContent>{children}</MainContent> {/* Corrected JSX tag */}
      </div>
    </div>
  );
};

export default Layout;
